import consumer from "./consumer"

consumer.subscriptions.create("ConversationsChannel", {

  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },
  received(data) {
    var conversation_id = data.conversation_id;
    var employer_conversation = $('[data-behavior = "employer_messages"][data-conversation-id =' + conversation_id + " ]");
    var employer_count = $('[data-behavior = "employer_count"][data-conversation-id =' + conversation_id + " ]");
    var employer_last_message = $('[data-behavior = "employer_last_message"][data-conversation-id =' + conversation_id + " ]");
    var user_last_message = $('[data-behavior = "user_last_message"][data-conversation-id =' + conversation_id + " ]");
    var user_count = $('[data-behavior = "user_count"][data-conversation-id =' + conversation_id + " ]");
    var user_conversation = $('[data-behavior = "user_messages"][data-conversation-id =' + conversation_id + " ]");
    var chatbox_employer_conversation = $('[data-behavior = "chatbox_employer_messages"][data-conversation-id =' + conversation_id + " ]");
    var online_employer_conversation = $('[data-behavior = "employer_online"][data-conversation-id =' + conversation_id + " ]");
    var online_user_conversation = $('[data-behavior = "user_online"][data-conversation-id =' + conversation_id + " ]");
    var chatbox_user_conversation = $('[data-behavior = "chatbox_user_messages"][data-conversation-id =' + conversation_id + " ]");

    var user_inbox = $('[data-behavior = "user_inbox"][data-inbox-id =' + conversation_id + " ]");
    var employer_inbox = $('[data-behavior = "employer_inbox"][data-inbox-id =' + conversation_id + " ]");
    var parentDiv = document.querySelector(".mesgsmesgs");

      if (data.current_user_id == data.message_user_id && data.current_user_role == 'user') {
        employer_count.removeClass('d-none')
        user_count.addClass('d-none')
        employer_conversation.append(data.message[1]);
        employer_count.text('')
        employer_count.append(data.count)
        employer_last_message.addClass('d-none')
        employer_last_message.text('')
        employer_last_message.append(data.message_last)
        employer_last_message.removeClass('d-none')
        user_last_message.addClass('d-none')
        user_last_message.text('')
        user_last_message.append(data.message_last)
        user_last_message.removeClass('d-none')
        online_user_conversation.addClass("online");
        user_conversation.append(data.message[0]);

        chatbox_employer_conversation.append(data.message[1]);
        $(".emp_notification").addClass("notifications");
        $(".emp_chat_notification").addClass("notifications_position");

        employer_inbox.addClass("unread_conversation");
        var lastScrollHeight = 0;
        function scrollToBottom() {
          //parentDiv.scrollTop = parentDiv.scrollHeight;
          // var scrollHeight = parentDiv.scrollHeight;
          // var clientHeight = parentDiv.clientHeight;
          // var maxScrollTop = scrollHeight - clientHeight;

          // // Set scrollTop to the maximum scroll position
          // parentDiv.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
          if (parentDiv){
            var sh = parentDiv.scrollHeight;
            if (sh != lastScrollHeight) {
              lastScrollHeight = sh;
              parentDiv.scrollTop = sh;
            }
          }
          
        }
        window.setInterval(scrollToBottom, 100);

      } else {
        // employer_count.addClass('d-none')
        user_count.removeClass('d-none')
        user_count.text('')
        employer_last_message.addClass('d-none')
        employer_last_message.text('')
        employer_last_message.append(data.message_last)
        employer_last_message.removeClass('d-none')
        user_last_message.addClass('d-none')
        user_last_message.text('')
        user_last_message.append(data.message_last)
        user_last_message.removeClass('d-none')
        user_count.append(data.count)
        online_employer_conversation.addClass("online")
        employer_conversation.append(data.message[0]);
        user_conversation.append(data.message[1]);
        chatbox_employer_conversation.append(data.message[0]);
        // chatbox_user_conversation.append(data.message[1]);
        $(".user_notification").addClass("notifications");
        $(".user_chat_notification").addClass("notifications_position");
        user_inbox.addClass("unread_conversation");
        var lastScrollHeight = 0;
        function scrollToBottom() {
          //parentDiv.scrollTop = parentDiv.scrollHeight;
          // var scrollHeight = parentDiv.scrollHeight;
          // var clientHeight = parentDiv.clientHeight;
          // var maxScrollTop = scrollHeight - clientHeight;

          // // Set scrollTop to the maximum scroll position
          // parentDiv.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
          if (parentDiv){
            var sh = parentDiv.scrollHeight;
            if (sh != lastScrollHeight) {
              lastScrollHeight = sh;
              parentDiv.scrollTop = sh;
            }
          }
          
        }
        window.setInterval(scrollToBottom, 100);
      }
    }
  });

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import $ from "jquery";
import conversation from "../channels/conversation";
import conversations_channel from "../channels/conversations_channel.js";


require("jquery")
require("@nathanvda/cocoon")
require("packs/jquery-ui.min.js");
require("packs/bootstrap.bundle.js");
require("packs/bootstrap-tokenfield.min.js");

import Splide from "@splidejs/splide";

$(document).on("turbolinks:load", function () {
  document.querySelectorAll("#testimonial-slider").forEach((carousel) =>
    new Splide(carousel, {
      type: "slide",
      perPage: 3,
      perMove: 1,
      rewind: true,
      arrows: false,
      autoplay: true,
      speed: 200,
      gap: 20,
      pagination: true,
      breakpoints: {
        768: {
          perPage: 2,
        },
        480: {
          perPage: 1,
        },
        375: {
          perPage: 1,
        },
      },
    }).mount()
  );

  $("#tokenfield").tokenfield({
    autocomplete: {
      source: function (request, response) {
        if (request.term.length >= 3){
          $.get("/admin/jobs/search_skills?term=" + request.term, {}, function (data) {
            response(data);
          });  
        }       
      }
    },
    showAutocompleteOnFocus: false,
    createTokensOnBlur: true,
  });
  $("#tokenfieldemployer").tokenfield({
    autocomplete: {
      source: function (request, response) {
        if (request.term.length >= 3){
          $.get("/employer/jobs/search_skills?term=" + request.term, {}, function (data) {
            console.log(data);
            response(data);
          });
        }
      },
      delay: 100,
    },
    showAutocompleteOnFocus: true,
    createTokensOnBlur: true, 
  });
  $("#tokenfields").tokenfield({
    autocomplete: {
      source: function (request, response) {
      },
      delay: 100,
    },
    showAutocompleteOnFocus: true,
    createTokensOnBlur: true,
  });
  $(".eduId_class").tokenfield({
    autocomplete: {
      source: function (request, response) {
      },
      delay: 100,
    },
    showAutocompleteOnFocus: true,
  });
});
$(document).on('click', '#eduIda', function() {
  $(".eduId_class").tokenfield({
    autocomplete: {
      source: function (request, response) {
      },
      delay: 100,
    },
    showAutocompleteOnFocus: true,
  });
});
$(document).on('click', '.availability_new', function(e) {
  e.preventDefault();
  window.location.href = $(this).attr('href');
});

Rails.start();
Turbolinks.start();
ActiveStorage.start();

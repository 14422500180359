$(document).on("turbolinks:load", function () {
  
  $("#new_message").on("submit", function (e) {
    e.preventDefault();
    // var parentDiv = document.querySelector('.scrol-to-bottom');

    // function scrollToBottom() {
    //   parentDiv.scrollTop = parentDiv.scrollHeight;
    // }
    // scrollToBottom();

    var formData = new FormData();
    var message = $(".input-message").val();
    var fileInput = $("#file_upload")[0].files[0];
    

    if (fileInput) {
      formData.append("message[file]", fileInput);
    }else  if (message.trim().length === 0) {
      return false;
    }
    formData.append("message[message]", message);
    $(".input-message").val(null);
    $("#file").val(null);
    $("#uploadFile").html(null);
    showLoader();
    disableInputs();

    $.ajax({
      url: $(this).attr("action"),
      method: $(this).attr("method"),
      data: formData,
      dataType: "script",
      processData: false,
      contentType: false,
      success: function () {
        $(".input-message").val(null);
        $("#file").val(null);
        $("#uploadFile").html(null);

      },
      error: function (xhr, status, error) {
        console.error(xhr.responseText);
      },
      complete: function () {
        hideLoader();
        enableInputs();
        $(".input-message").focus();
      }
    });
    return false;
  });
  function showLoader() {
    // Show loader
    $(".loader").show();
    $(".loader2").hide();
  }

  function hideLoader() {
    // Hide loader
    $(".loader").hide();
    $(".loader2").show();
  }
  function disableInputs() {
    // Disable input fields
    $(".input-message").prop("disabled", true);
    $("#file_upload").prop("disabled", true);
  }
  function enableInputs() {
    // Enable input fields
    $(".input-message").prop("disabled", false);
    $("#file_upload").prop("disabled", false);
  }
});

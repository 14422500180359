import consumer from "./consumer"

consumer.subscriptions.create("NewConversationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {

    var conversation_id = data.conversation_id;
    var employer_conversation = $('[data-behavior = "employer_messages"][data-conversation-id =' + conversation_id + " ]");
    var user_conversation = $('[data-behavior = "user_messages"][data-conversation-id =' + conversation_id + " ]");
    var chatbox_employer_conversation = $('[data-behavior = "chatbox_employer_messages"][data-conversation-id =' + conversation_id + " ]");
    var chatbox_user_conversation = $('[data-behavior = "chatbox_user_messages"][data-conversation-id =' + conversation_id + " ]");

    var user_inbox = $('[data-behavior = "user_inbox"][data-inbox-id =' + conversation_id + " ]");
    var employer_inbox = $('[data-behavior = "employer_inbox"][data-inbox-id =' + conversation_id + " ]");
    var parentDiv = document.querySelector(".scrol-to-bottom");

    var new_conversation = $('#new_conversation_' + data.receiver_id);
    var new_conversation_chatbox = $("#new_conversation_chatbox_" + data.receiver_id);
    var clear_no_record = $('#no-record_' + data.receiver_id);

    if (data.current_user_id == data.message_user_id && data.current_user_role == 'user') {
      // employer_conversation.append(data.message[0]);
      // user_conversation.append(data.message[1]);
      if (data.new_conversation_check == true) {
          chatbox_user_conversation.append(data.message[1]);
          chatbox_employer_conversation.append(data.message[0]);
          $(".emp_notification").addClass("notifications");
          $(".emp_chat_notification").addClass("notifications_position");
          employer_inbox.addClass("unread_conversation");
          var lastScrollHeight = 0;
          function scrollToBottom() {
            //parentDiv.scrollTop = parentDiv.scrollHeight;
            // var scrollHeight = parentDiv.scrollHeight;
            // var clientHeight = parentDiv.clientHeight;
            // var maxScrollTop = scrollHeight - clientHeight;
  
            // // Set scrollTop to the maximum scroll position
            // parentDiv.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
            if (parentDiv){
              var sh = parentDiv.scrollHeight;
              if (sh != lastScrollHeight) {
                lastScrollHeight = sh;
                parentDiv.scrollTop = sh;
              }
            }
            
          }
          window.setInterval(scrollToBottom, 100);
      } else {
      chatbox_user_conversation.append(data.message[1]);
      chatbox_employer_conversation.append(data.message[0]);
      var lastScrollHeight = 0;
      function scrollToBottom() {
        //parentDiv.scrollTop = parentDiv.scrollHeight;
        // var scrollHeight = parentDiv.scrollHeight;
        // var clientHeight = parentDiv.clientHeight;
        // var maxScrollTop = scrollHeight - clientHeight;

        // // Set scrollTop to the maximum scroll position
        // parentDiv.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        if (parentDiv){
          var sh = parentDiv.scrollHeight;
          if (sh != lastScrollHeight) {
            lastScrollHeight = sh;
            parentDiv.scrollTop = sh;
          }
        }
        
      }
      window.setInterval(scrollToBottom, 100);
      }

    } else {
      if (data.new_conversation_check == true) {
        clear_no_record.html("").removeClass('active_chat');
        new_conversation.html("");
        new_conversation.append(data.message[2]);
        new_conversation_chatbox.append(data.message[3]);
        user_conversation.append(data.message[0]);
        chatbox_user_conversation.append(data.message[0]);
      $(".user_notification").addClass("notifications");
      $(".user_chat_notification").addClass("notifications_position");
      user_inbox.addClass("unread_conversation");
      var lastScrollHeight = 0;
      function scrollToBottom() {
        //parentDiv.scrollTop = parentDiv.scrollHeight;
        // var scrollHeight = parentDiv.scrollHeight;
        // var clientHeight = parentDiv.clientHeight;
        // var maxScrollTop = scrollHeight - clientHeight;

        // // Set scrollTop to the maximum scroll position
        // parentDiv.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        if (parentDiv){
          var sh = parentDiv.scrollHeight;
          if (sh != lastScrollHeight) {
            lastScrollHeight = sh;
            parentDiv.scrollTop = sh;
          }
        }
        
      }
      window.setInterval(scrollToBottom, 100);
      } else {
      // employer_conversation.append(data.message[0]);
        $(".user_notification").addClass("notifications");
        $(".user_chat_notification").addClass("notifications_position");
         chatbox_user_conversation.append(data.message[1]);
         var lastScrollHeight = 0;
          function scrollToBottom() {
            //parentDiv.scrollTop = parentDiv.scrollHeight;
            // var scrollHeight = parentDiv.scrollHeight;
            // var clientHeight = parentDiv.clientHeight;
            // var maxScrollTop = scrollHeight - clientHeight;
  
            // // Set scrollTop to the maximum scroll position
            // parentDiv.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
            if (parentDiv){
              var sh = parentDiv.scrollHeight;
              if (sh != lastScrollHeight) {
                lastScrollHeight = sh;
                parentDiv.scrollTop = sh;
              }
            }
            
          }
          window.setInterval(scrollToBottom, 100);
      }
    }
  }
})
